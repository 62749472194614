<template>
  <div class="billing-address" v-if="customerCustomizingFields">

    <div class="setting-div">
      <div class="con-1">
        <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('Zusatzfeld-1')" 
          v-model="customerCustomizingFields"/>
      </div>
      <div class="con-2">
        <vs-textarea v-model="textarea" class="md-textarea"/>
      </div>
    </div>

    <div class="setting-div">
      <div class="con-1">
        <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('Zusatzfeld-2')" v-model="data"/>
      </div>
      <div class="con-2">
        <vs-textarea v-model="textarea" class="md-textarea"/>
      </div>  
    </div>

    <div class="setting-div">
      <div class="con-1">
        <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('Zusatzfeld-3')" v-model="data"/>
      </div>
      <div class="con-2">
        <vs-textarea v-model="textarea" class="md-textarea"/>
      </div>  
    </div>

    <div class="setting-div">
      <div class="con-1">
        <vs-input class="inputx sm-input mb-2 mr-2" :label-placeholder="$t('Zusatzfeld-4')" v-model="data"/>
      </div>
      <div class="con-2">
        <vs-textarea v-model="textarea" class="md-textarea"/>
      </div>  
    </div>

  <h1>Customer Customizing FIleds</h1>
{{customerCustomizingFields}}
  <h1>asdasd</h1>
{{getField("extra_1")}}

  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'IdAdditionalFields',

  data() {
    return {
      data: "TEXTAREAD",
      textarea: "TEXTAREAD",
    }
  },

  async mounted() {
    await this.$store.dispatch('customizing/loadCustomerCustomizingFields')
  },

  computed: {
    customerCustomizingFields() {
      return this.$store.getters['customizing/getCustomerCustomizingFields']
    }
  },

  methods: {
    getField(name) {
      return this.customerCustomizingFields.filter( f => f.field_name === name)
    }
  },


});
</script>
<style>

</style>